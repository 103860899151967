import { useEffect, useRef } from "react";
import style from './Water.module.scss';

function Water() {
    const canvasRef = useRef(null);
  
    useEffect(() => {
        const canvas = canvasRef.current;
        const gl = canvas.getContext("webgl2");
        const dpr = Math.max(1, .5 * window.devicePixelRatio);

        const touches = new Map();const vertexSource = `#version 300 es
#ifdef GL_FRAGMENT_PRECISION_HIGH
precision highp float;
#else
precision mediump float;
#endif
in vec2 position;
void main(void) {
gl_Position = vec4(position, 0., 1.);}`;
const fragmentSource = `#version 300 es
#ifdef GL_FRAGMENT_PRECISION_HIGH
precision highp float;
#else
precision mediump float;
#endif
out vec4 fragColor;uniform vec2 resolution;uniform float time;
const vec3 re=vec3(6,4,6)*1.25;
#define T time
float nx(vec3 p,vec3 s,float r) {p=abs(p)-s;return length(p*0.05)-0.6;}
float mat=.0;float bp(vec3 p){ float rm=-nx(p,re,0.5);return rm;}
vec3 ce(vec2 uv) {vec2 n=vec2(0), q=vec2(0); uv*=0.98;  float d=dot(uv,uv), 
s=9.,a=.02, b=sin(T*4.4-d*92.3)*1.2;mat2 m=mat2(.6,1.2,-1.2,.6); 
 for (float i=.0; i<30.; i++) {n*=m; q=uv*s+b+i+n; a+=dot(sin(q)/s,vec2(.2)); 
n-=sin(q); s*=1.2;}vec3 col=vec3(.5,.1,3)*(a+a)+a+a-d; return col;}
vec3 nm(vec3 p){vec3 n=vec3(1) ; return n;}void main() 
{vec2 uv= (gl_FragCoord.xy-.5*resolution)/888.8;vec3 col=vec3(0), 
 ro=vec3(0,.5,1.6),rd=vec3(uv,1);vec3 p=ro; for (float i=.0; i<252.2; i++)
{ float d=bp(p); if (d<0.002) {vec3 n=nm(p); 
{col+=ce(p.xy*0.1);};break;} p+=rd*0.4;}fragColor =vec4(col,1);}`;
let time;
let buffer;
let program;
let resolution;
let pointerCount;
let vertices = [];
let touching = false;

function resize() {
  const { width, height } = canvas;
  canvas.width = width * dpr;
  canvas.height = height * dpr;gl.viewport(0, 0, width * dpr, height * dpr);}

function compile(shader, source) {gl.shaderSource(shader, source); gl.compileShader(shader);
if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(shader));
  }
}

function setup() {
  const vs = gl.createShader(gl.VERTEX_SHADER);
  const fs = gl.createShader(gl.FRAGMENT_SHADER);

  program = gl.createProgram();

  compile(vs, vertexSource);
  compile(fs, fragmentSource);

  gl.attachShader(program, vs);
  gl.attachShader(program, fs);
  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
  }

  vertices = [-1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0];

  buffer = gl.createBuffer();

  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);

  const position = gl.getAttribLocation(program, "position");

  gl.enableVertexAttribArray(position);
  gl.vertexAttribPointer(position, 2, gl.FLOAT, false, 0, 0);

  time = gl.getUniformLocation(program, "time");
  
  pointerCount = gl.getUniformLocation(program, "pointerCount");
  resolution = gl.getUniformLocation(program, "resolution");
}

function draw(now) {
  gl.clearColor(0, 0, 0, 1);
  gl.clear(gl.COLOR_BUFFER_BIT);

  gl.useProgram(program);
  gl.bindBuffer(gl.ARRAY_BUFFER, null);
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);

  gl.uniform1f(time, now * 0.0002);
  
  gl.uniform1i(pointerCount, touches.size);
  gl.uniform2f(resolution, canvas.width, canvas.height);
  gl.drawArrays(gl.TRIANGLES, 0, vertices.length * 0.5);
}

function getTouches() {
  if (!touches.size) {
    return [0, 0];
  }

  for (let [id, t] of touches) {
    const result = [dpr * t.clientX, dpr * (window.innerHeight - t.clientY)];

    return result;
  }
}



function loop(now) {
  draw(now);
  requestAnimationFrame(loop);
}

function init() {
  setup();
  resize();
  loop(0);
}

init();
window.onresize = resize;

}, []);

      
    return <div className={style.wellContainer}>
        <canvas ref={canvasRef} className={style.canvas} id="canvas" width={500} height={500}></canvas>
      </div>;
}

export default Water;