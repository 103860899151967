import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles.module.scss';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './routes/App/App';
import Play from './routes/Play/Play';
import Home from './routes/Home/Home';
import { TransactionProvider } from './context/TransactionContext';
import './index.css';

const router = createBrowserRouter([
  {path: '/', element: <App />, children: [
    {path: '/', element: <Home />},
    {path: '/play', element: <Play />}
  ]}
]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <TransactionProvider>
    <RouterProvider router={router} />
    </TransactionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
