import Water from '../../components/Water/Water';
import style from './Home.module.scss';
import generalStyle from '../../Styles.module.scss';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { TransactionContext } from '../../context/TransactionContext';
import Metamask from '../../components/Metamask/Metamask';

function Home() {

  const {walletConnectedAddress} = useContext(TransactionContext);

    return <>
        <div className={style.mainContent}>
          <h1>Embrace the Power of Positivity</h1>
          <p>Our website is dedicated to spreading joy, inspiration, and heartfelt blessings to all our users. Whether you're seeking encouragement,
            a boost of motivation, or simply a reminder of the beauty that surrounds us, you've come to the right place.
          </p>
            {walletConnectedAddress ? <Link className={generalStyle.Primary} to='/play'>MAKE A WISH</Link>
             : <div className={style.alignMainBtns}>
              <Metamask />
             <Link className={generalStyle.Secoundary} to='/play'>Without Connect</Link>
             </div>
             }
        </div>
        <div className={style.waterPosition}>
        <Water />
        </div>
    </>;
}

export default Home;