import { useEffect, useState } from "react";
import Dialog from "../../components/Dialog/Dialog";
import Panel from "../../components/Panel/Panel";
import Water from "../../components/Water/Water";
import generalStyle from '../../Styles.module.scss';

function Play() {
    const [wishContent, setWishContent] = useState('');

    useEffect(() => {
        setWishContent('');
    }, [])
    const dialogContent = () => {
        return <div className={generalStyle.dialogContentContainer}>
            <h3>This wish will come true...</h3>
            <p className={generalStyle.wishContent}>
                {wishContent ? wishContent : 'Please wait a few seconds...'}
            </p>
        </div>
    }
    return <>
        <Panel setWishContent={setWishContent} />
        <Water />
        <Dialog dialogContent={dialogContent} />
    </>;
}

export default Play;