import { useContext, useState } from "react";
import style from './Dialog.module.scss';
import { TransactionContext } from "../../context/TransactionContext";
import generalStyle from '../../Styles.module.scss';

function Dialog({dialogContent, isShouldOpen = false}: any) {
    const {isSuccess, setIsSuccess, getWetTokens, walletConnectedAddress} = useContext(TransactionContext);

    const finishBtnAndSendWET = () => {
        if (walletConnectedAddress) {
            setIsSuccess(false);
            getWetTokens();
        } else {
            alert('Please connect your wallet');
        }
       
    };

    return (  
    <>
        <dialog className={style.dialogContainer + ' ' + style.openWishDialog} open={isSuccess|| isShouldOpen}>
            { dialogContent() }
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button className={generalStyle.Primary} onClick={() => setIsSuccess(false)}>Finish</button>
            {isSuccess && <button className={generalStyle.Secoundary} onClick={() => finishBtnAndSendWET()}>Get WET Tokens</button>}
            </div>
        </dialog>
       {isSuccess && <div className={generalStyle.backgroundDialog}></div>}
    </>
    );
}

export default Dialog;