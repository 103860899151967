import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/Header';
import style from './App.module.scss';
import Water from '../../components/Water/Water';
import { useContext, useEffect } from 'react';
import { TransactionContext } from '../../context/TransactionContext';

function App() {

  const {getEthereumContract} = useContext(TransactionContext);

  useEffect(() => {
  }, []);

  return (
   <>
    <div className={style.App}>
      <div className={style.flegEnv}></div>
    <Header />
        <Outlet />
    </div>
    </>
   
   
  );
}

export default App;
