import React, { useEffect, useState } from 'react';
import {ethers} from 'ethers';
import { contractABI, contractAddress, tokenContractAddress } from '../util/contracts';

export const TransactionContext = React.createContext<any>(null);

declare var window: any

const { ethereum } = window;


const getEthereumContract = async () => {
    const provider = new ethers.BrowserProvider(ethereum);
    const signer = await provider.getSigner();
    const transactionContract: any = new ethers.Contract(contractAddress, contractABI, signer);

   return transactionContract;
}



export const TransactionProvider = ({children}: any): JSX.Element => {
    const [walletConnectedAddress, setWalletConnectedAddress] = useState();
    const [formData, setFormData] = useState({amount: '', message: ''});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const addressTo = '0x508007f1c16Cd3533EE46a433C445E0560aA6404';
    const handleChange = (e: any) => {
        setFormData((prevState) => ({...prevState, [e.target.name]: e.target.value}));
    }

    const checkWalletConnected = async () => {
        if(!ethereum) return;
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        if(accounts.length) {
            setWalletConnectedAddress(accounts[0]);
        } 
    }

    const getWetTokens = async () => {
        try {
        const transactionContract = await getEthereumContract();
        const { amount }: any = formData;
        const parsedAmount = ethers.parseEther(amount);
        const sendWET = await transactionContract.sendWET(tokenContractAddress, addressTo, parsedAmount);
        await sendWET.wait();
        } catch (err) {
            console.log(err);
            setIsSuccess(false);
            throw new Error('No eth obj');
        }
    }

    const sendTransaction = async () => {
        try {
        if(!ethereum) return alert('Please install Metamask');

        const { amount, message }: any = formData;
        const transactionContract = await getEthereumContract();
        const parsedAmount = ethers.parseEther(amount);
        

        await ethereum.request({
            method: 'eth_sendTransaction',
            params: [{
                from: walletConnectedAddress,
                to: addressTo,
                value: Number(amount * 1e18).toString(16),
                gasLimit: '0x5208',
                maxPriorityFeePerGas: '0x3b9aca00',
                maxFeePerGas: '0x2540be400'
            }]
        });
        
        const transactionHash = await transactionContract.wellWishingPayment(addressTo, parsedAmount, amount, message);
        setIsLoading(true);
        await transactionHash.wait();
        setIsLoading(false);
        setIsSuccess(true);

        
        } catch(e) {
            console.log(e);
            setIsSuccess(false);
            throw new Error('No eth obj');
        }
      }

    useEffect(() => {
        checkWalletConnected();
    }, []);

   return (
    <TransactionContext.Provider value={{ethereum, getEthereumContract, walletConnectedAddress, formData, setFormData, handleChange, sendTransaction, setIsLoading, isLoading, isSuccess, setIsSuccess, getWetTokens}}>
        {children}
    </TransactionContext.Provider>
   )
}