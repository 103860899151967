import metaLogo from '../../MetaMask_Fox.svg.png';
import generalStyle from '../../Styles.module.scss';
import { TransactionContext } from '../../context/TransactionContext';
import headerStyle from '../Header/Header.module.scss';
import style from './Metamask.module.scss';
import {ethers} from "ethers";
import { useContext, useEffect, useState } from 'react';

declare let window:any;

const Metamask = ({changeClass}: any): JSX.Element => {
    const [currentAccount, setCurrentAccount] = useState<string | undefined>();
    const [isEtereum, setIsEtereum] = useState<boolean>(true);
    const [dialogState, setDialogState] = useState(false);

    const {walletConnectedAddress} = useContext(TransactionContext);
    
    useEffect(() => {
        if (walletConnectedAddress) {
            const postReg = async () => {
            try {
                const user = await fetch('https://blesson.onrender.com/auth/connect', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        },
                    body: JSON.stringify({wallet: walletConnectedAddress})
                });
                return user;
            
            } catch(err) {
                console.log(err)
            }
        }
          postReg().then(res => console.log(res));   
    } 
    }, [walletConnectedAddress]);

    const metamaskInstall = () => {
        return (
            dialogState && <div className={generalStyle.flexCenter + ' ' + style.dialogWrapper}>
            <dialog className={style.dialogEl} open={dialogState}>
            <div>
               <div className={style.contentInstallMeta}>
                <img className={style.metaIcon} src={metaLogo} alt="metamask logo" />
                <h2>Install Metamask</h2>
                <span>Go to the Metamask website: https://metamask.io/
                Click on the "Get Chrome Extension" (or "Get Firefox Extension") button, depending on your browser.
                </span>
               </div>
            <button className={generalStyle.Secoundary} onClick={() => setDialogState(false)}>Close</button>
            </div>
        </dialog>
            </div>
        )
      }

    const onClickConnect = () => {
        if(!window.ethereum) {
            setIsEtereum(false);
            setDialogState(true);
          return;
        }
        setIsEtereum(true);
        const provider = new ethers.BrowserProvider(window.ethereum);
        provider.send("eth_requestAccounts", [])
        .then((accounts) => {

          if (accounts.length > 0) setCurrentAccount(accounts[0]);
        })
        .catch((e)=>console.log(e))
      }

      useEffect(() => {
        if (walletConnectedAddress) setCurrentAccount(walletConnectedAddress);
      }, [walletConnectedAddress]);


    return (
        <>
        {
            !currentAccount ? <button onClick={onClickConnect} className={generalStyle.Primary + ' ' + changeClass} type="button">
            <img className={headerStyle.iconImg} src={metaLogo} alt="metaMask Logo" />
            <span>Connect</span>
            </button> : <div className={generalStyle.Secoundary}>
                <span>{currentAccount?.slice(0,4) + '...' + currentAccount?.slice(-4)}</span>
            </div>
        }
         {!isEtereum && metamaskInstall()}
        </>
    )
    
   
  }

  export default Metamask;