import style from './Header.module.scss';
import { Link } from 'react-router-dom';
import Metamask from '../Metamask/Metamask';
import generalStyle from '../../Styles.module.scss';
    
const Header = (): JSX.Element => {

    return (
    <div className={style.headerContainer}>
        <div className={style.headerWidth}>
        <ul>
            <li><Link to='/'>Hello</Link></li>     
            <li><Link to='/play'>Make a wish</Link></li>     
        </ul>
        <div className="login">
            <Metamask changeClass={generalStyle.changes} />
        </div>
        </div>
    </div>
    );
}

export default Header;