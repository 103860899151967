import style from './Panel.module.scss';
import generalStyle from '../../Styles.module.scss';
import { useContext, useEffect, useState } from 'react';
import { TransactionContext } from '../../context/TransactionContext';
import ethCoinIcon from '../../eth_coin_icon.png';
import magicIcon from '../../magic_icon.png';


function Panel({setWishContent} : any) {
    const {walletConnectedAddress, handleChange, formData, sendTransaction, isLoading, setIsLoading, setIsSuccess, isSuccess} = useContext(TransactionContext);
    const [fakeTransaction, setFakeTransaction] = useState(false);

    const handleSubmit = (e: any) => {
        let { amount, message } = formData;
        e.preventDefault();
        setIsLoading(true);
        if (fakeTransaction) {
            setTimeout(() => {
                setIsSuccess(true);
                setIsLoading(false);
            }, 5000);
            return;
        }
        

        if (!amount || !message) return;

        sendTransaction();
    }

    const skipTransaction = () => {
        setFakeTransaction(true);
    }
    useEffect(() => {
        setFakeTransaction(false);
        let { message } = formData;
        if (isSuccess) {
            
            const generateBless = async () => {
                const response = await fetch('https://blesson.onrender.com/generate-bless', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        },
                    body: JSON.stringify({
                        prompt:  'please write a short bless for me to have a ' + message + ' and add relevant emojies',
                    })
                })  

                if (response.ok) {
                    const {prompt} = await response.json();
                    setWishContent(prompt);
                    saveBless(prompt);
                   }  else {
                    const err = await response.text();
                    alert(err);
                   }
            };
             generateBless();

            const saveBless = async (prompt: string) => {
                    const response = await fetch('https://blesson.onrender.com/blesses/create-bless', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        },
                    body: JSON.stringify({
                        wallet: fakeTransaction ? '0x508007f1c16Cd3533EE46a433C445E0560aA6404' : walletConnectedAddress,
                        content: prompt,
                        createdAt: Date.now()
                    })
                });
            
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                   }  else {
                    const err = await response.text();
                    alert(err);
                   }
            };
        }
        
    }, [isSuccess])
    return ( 
    <>
        {!isSuccess && <div className={`${style.panelContainer} ${isLoading ? style.panelMove: null}`}>
        {isLoading && <div className={style.coverForm}></div>}
           {(fakeTransaction || walletConnectedAddress) ? <div className={style.panelContent}>
                <h2>Get a Bless!</h2>
                <p>Through a peny to the wishing well, and choose a bless type.</p>
            </div>
            :
            <div className={style.panelContent}>
                <h2>please connect</h2>
                <p>please connect with Metamask</p>
                <span>---- Or ----</span>
                {<button onClick={skipTransaction} style={{marginTop: 8}} className={generalStyle.Primary}>
                    Make a wish without WET tokens </button>}
            </div>}
            <form onSubmit={handleSubmit}>
                {!(fakeTransaction || walletConnectedAddress) && <div className={style.disableForm}></div>}
                {!fakeTransaction && <div className={generalStyle.formGroup}>
                    <label><img src={ethCoinIcon} alt="eth coin" /></label>
                    <input className={generalStyle.fullWidth} name="amount" type="number" min={0.1} max={10000} step={0.1} onChange={handleChange} />
                </div>}
                <div className={generalStyle.formGroup}>
                    <label><img src={magicIcon} alt="eth coin" /></label>

                    <select  className={generalStyle.fullWidth} name="message" onChange={handleChange} >
                        <option defaultValue={''}></option>
                        <option value="wonderful day">a daily bless</option>
                        <option value="healing">to be heal</option>
                        <option value="richness">to be rich</option>
                        <option value="loving">to be loved</option>
                    </select>

                </div>
                <button className={generalStyle.Primary + ' ' + generalStyle.fullWidth} type="submit">wish now!</button>
            </form>
        </div>}
         {isLoading && <div className="lds-ripple"><div></div><div></div></div>}
    </>
     );
}

export default Panel;