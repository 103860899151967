import abi from './Transactions.json';


// &&&& GOERLI &&&&
// export const contractAddress = '0xEa03A6411Cc55FdDEFbb14c4E540EFD4EE1920D6';

// &&&& MUMBAI &&&&
export const contractAddress = '0x2Fb19059f31a7d939470D55563ca9B19302606C5';

export const contractABI = abi.abi;

export const tokenContractAddress = '0x12d771F83786a7513B6BE642f14822a2B9D86e49';